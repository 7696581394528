@use './abstracts' as *;

.header {
    align-items: center;
    background-color: #CECECE;
    display: flex;
    font-size: $desktopHeaderSize;
    height: 6.5%;
    justify-content: space-between;
    padding: 0 36px;
    a {
        text-decoration: none;
        &:visited {
            color: #000000;
        }
    }
}
@font-face {
    font-family: 'Poppins';
    src: url('../assets/Poppins-Regular.ttf');
}

html {
    font-size: clamp(14px, 1.5vw, 24px);
}

body {
    height: 100vh;
    font-family: 'Poppins';
    margin: 0;
    min-height: 720px;
}

#root {
    height: 100%;
}

::-webkit-scrollbar {
    width: 16px;
}
  
::-webkit-scrollbar-track {
    background: #ffffff;
    border: solid #e1e1e1 1px;
    border-radius: 8px;
}
  
::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    background-clip: padding-box;
    border: 4.5px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
}

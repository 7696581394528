@use '../abstracts' as *;

.newCheckbox, .checkbox {
    align-items: center;
    background-color: white;
    border: solid #00000000 1px;
    border-radius: 18px;
    display: flex;
    height: 3em;
    justify-content: space-between;
    margin: auto;
    padding: 0.4em 0.8em;
    width: calc(100% - 1.6em);
    + .checkbox {
        margin-top: 1em;
    }
    svg {
        fill: #79747E;
        transition: all .25s ease;
    }
    span, svg {
        &:hover {
            cursor: pointer;
            fill: #000000;
        }
    }
    .inputContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        input {
            all: unset;
            font-family: 'Poppins';
        }
        .title {
            font-size: $desktopSubheaderSize;
            font-weight: 900;
        }
        .content {
            font-size: $desktopTextSize;
        }
        + .checkbox {
            margin-top: 1em;
        }
    }
    .title {
        font-size: $desktopSubheaderSize;
        font-weight: 900;
    }
    .content {
        font-size: $desktopTextSize;
    }
    .buttonContainer {
        display: flex;
        align-items: center;
        input {
            width: 1.5em;
            height: 1.5em;
            &:checked {
                background-color: purple;
            }
        }
        .interface {
            align-items: center;
            display: flex;
            position: relative;
            .options {
                background-color: #00000000;
                border-radius: 9px;
                color: #00000000;
                display: flex;
                flex-direction: column;
                font-size: $desktopTextSize;
                left: -240%;
                padding: 0.25em 1em;
                position: absolute;
                transition: all .25s ease;
                z-index: -1;
            }
        }
    }
}

#active {
    background-color: #cecece;
    color: #000000;
    z-index: 1;
}
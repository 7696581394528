@use '../abstracts/' as *;

.formOuter {
    flex-direction: column;
    position: relative;
    input {
        @include unstyle();
        background-color: #FFFFFF;
        border-radius: 10px;
        font-size: $desktopSubheaderSize;
        font-family: 'Poppins';
        margin-top: .5em;
        padding-left: .5em;
        height: 3em;
    }
    span {
        &:nth-of-type(1n) {
            font-size: $desktopSubheaderSize;
            font-weight: 100;
            font-style: italic;
            color: red;
            margin-left: 1em;
        }
        @media screen and (max-width: 1415px){
            position: absolute;
            left: .5em;
            top: -1.5em;
        }
    }
    .success {
        &:nth-of-type(1n) {
            position: absolute;
            bottom: -2.25em;
            left: 2.5%;
            top: unset;
            color: green;
            margin-left: 0;
        }
    }
    .currentOuter {
        display: flex;
        justify-content: center;
        .currentInner {
            display: flex;
            flex-direction: column;
            width: 45%;
            &:nth-of-type(2n) {
                margin-left: 5%;
            }
        }
    }
    .formInner {
        display: flex;
    }
    form {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
        div {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-top: 1.5em;
            label {
                padding-left: .5em;
                position: relative;
            }
        }
        button {
            @include button();
            margin-top: 1.5em;
            padding-left: .5em;
            height: 3em;
            width: 55%;
            align-self: flex-start;
            margin-left: 5%;
        }
    }
}

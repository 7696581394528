@use '../abstracts' as *;

.home {
    display: flex;
    height: $desktopContentHeight;
    > img {
        &:hover {
            cursor: pointer;
        }
        position: fixed;
        bottom: 24px;
        right: 48px;
    }
    .content {
        background-color: #F6F6F6;
        padding: 48px;
        overflow: auto;
        width: 100%;
        .containerOuter {
            font-size: $desktopTextSize;
            height: 85%;
            max-height: calc((clamp(0.8rem, 1.25vw, 2.4rem) * 11) * 2 + 112px);
            &:nth-of-type(odd) {
                border-bottom: solid #dedede 1px;
            }
            &:nth-of-type(even) {
                margin-top: 48px;
            }
            > span {
                font-size: $desktopHeaderSize;
                margin-left: 0.5em;
            }
            .selectInner {
                display: flex;
                align-items: center;
            }
            .addGraph {
                align-items: center;
                border: solid #e5e5e6 1px;
                border-radius: 12px;
                color: #a3a2a4;
                display: flex;
                font-size: $desktopHeaderSize;
                height: calc(clamp(16px, 1vw, 2rem) * 2.3966);
                padding: 0 24px;
            }
            .select {
                align-items: center;
                display: flex;
                font-size: $desktopHeaderSize;
                justify-content: space-between;
                padding-right: 64px;
            }
        }
    }
}
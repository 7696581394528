@use '../abstracts/' as *;

.settings {
    display: flex;
    height: $desktopContentHeight;
    .directions {
        font-weight: 900;
        width: 97.5%;
        padding-left: 2.5%;
        margin: 5.5% 0;
    }
    .submenuContainer {
        @include content-left();
        border-right: solid #d8d8d8 1px;
        height: calc(100% - 1em);
        padding-top: 0;
        position: relative;
        .header {
            align-items: center;
            display: flex;
            font-size: $desktopHeaderSize;
            font-weight: 900;
            height: 22.5%;
            justify-content: flex-start;
            padding-left: 12px;
            margin: auto;
            width: 80%;
        }
        .submenu {
            display: flex;
            font-size: $desktopSubheaderSize;
            flex-direction: column;
            margin: auto;
            width: 80%;
            span {
                cursor: pointer;
                border-radius: 10px;
                padding: 6px 12px;
                transition: all .25s ease;
                +span {
                    margin-top: 1em;
                }
            }
        }
    }
    .delete {
        position: absolute;
        bottom: 1em;
        width: 64.125%;
    }
    .content {
        display: flex;
        flex-direction: column;
        font-size: $desktopHeaderSize;
        width: 100%;
        .contentHeader {
            align-items: center;
            background-color: $purple;
            color: #FFFFFF;
            display: flex;
            font-size: $desktopSubheaderSize;
            font-weight: 900;
            padding-left: 4em;
            transition: all .25s ease;
            div {
                font-size: $desktopHeaderSize;
                width: 97.5%;
                padding-left: 2.5%;
                margin: 7.5% 0;
            }
        }
        .contentHeader#active {
            background-color: #E32F2F;
        }
        .contentInner {
            background-color: #f6f6f6;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            font-size: clamp(16px, 0.9vw, 2rem);
            padding: 0 4em;
            overflow: auto;
        }
        .formOuter {
            display: flex;
        }
    }
}

#active {
    background-color: $purple;
    color: #FFFFFF;
}

.delete#active {
    background-color: #E32F2F;
}
@use '../abstracts/' as *;

.new {
    align-items: center;
    display: flex;
    height: 80%;
    .formOuter {
        width: 50%;
        > span {
            font-size: $desktopHeaderSize;
        }
    }
    .newDataset {
        margin-top: 5em;
        span {
            font-weight: 100;
        }
        label {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .spacer {
                width: 30%;
                margin-right: 0.5em;
                border-top: solid black 0.5px;
                height: 0px;
                display: inline-block;
            }
        }
        button {
            @include button();
            align-self: flex-end;
            margin-top: 1.5em;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
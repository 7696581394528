@use '../abstracts' as *;

.login {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
    height: 70%;
    justify-content: space-around;
    width: 30%;
    text-align: center;

    .welcome {
        display: flex;
        flex-direction: column;
        font-size: $desktopHeaderSize;
        width: 100%;
        span {
            font-weight: 900;
            &:nth-of-type(2n) {
                font-size: $desktopSubheaderSize;
                font-weight: 100;
            }
        }
    }
}
@use '../abstracts' as *;

form {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    .error {
        color: red;
        position: absolute;
        top: 10%;
    }
    span {
        &:nth-of-type(1n) {
            font-weight: 900;
            font-size: $desktopHeaderSize;
        }
    }
    .interfaceOuter {
        width: 50%;
        margin-left: 64px;
        margin-right: 64px;
        .inputContainer {
            position: relative;
            width: fit-content;
            margin: auto;
            margin-top: 15%;
            width: 100%;
            input {
                border: 0;
                border-bottom: solid #9f9f9f 1px;
                font-family: 'Poppins';
                font-size: $desktopSubheaderSize;
                height: calc($desktopSubheaderSize * 2);
                padding: 0;
                width: 100%;
                + label {
                    color: #9f9f9f;
                    position: absolute;
                    font-size: $desktopSubheaderSize;
                    left: 2px;
                    top: 4px;
                    transition: all .25s ease;
                    pointer-events: none;
                }
                &:focus, &:not(:placeholder-shown) {
                    @include unstyle();
                    + label {
                        top: -20px;
                        font-size: $desktopLabelFocusSize;
                    }
                }
            }
        }
        button {
            @include button();
        }    
    }
}

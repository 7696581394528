@use './abstracts' as *;

.addGraph {
    align-items: center;
    border: solid #e5e5e6 1px;
    border-radius: 12px;
    color: #a3a2a4;
    display: flex;
    font-size: $desktopHeaderSize;
    height: calc(clamp(16px, 1vw, 2rem) * 2.3966);
    padding: 0 24px;
    transition: all .25s ease;
    &:hover {
        cursor: pointer;
        background-color: #FFFFFF;

    }
}

@use '../abstracts' as *;

.container {
    border-radius: 10px;
    display: grid;
    grid-gap: 1.2em;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 30px), 1fr));
    height: calc(100% - 1em - 87px - 18px);
    padding: 0 48px;
    margin-top: 18px;
    overflow: overlay;
    overflow-x: hidden;
}
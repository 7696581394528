@use '../abstracts' as *;

.card {
    background-color: #e6e0e9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-size: $desktopSubheaderSize;
    height: 11em;
    padding: 24px;
    position: relative;
    transition: all 0.25s ease;
    width: calc(100% - 48px);
    &:hover {
        cursor: pointer;
        background-color: #ad98ff;
    }
    .graphName {
        align-items: center;
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        width: 95%;
    }
    .graphDescription {
        font-weight: 100;
        margin-top: 2em;
        word-wrap: break-word;
    }
    .interface {
        background-color: transparent;
        border-radius: 7px;
        color: transparent;
        display: flex;
        justify-content: space-between;
        padding: 6px 12px;
        position: absolute;
        right: 6px;
        top: 18px;
        transition: all .25s ease;
        width: 50%;
        .buttons {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: -1;
        }
        span {
            &:nth-of-type(odd) {
                margin-bottom: 12px;
                &:hover {
                    color: red;
                }
            }
            &:nth-of-type(even) {
                &:hover {
                    color: green;
                }
            }
        }
    }
    .deleteOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: #00000095;
        backdrop-filter: blur(3px);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;
        .buttons {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 24px;
            span {
                transition: all .25s ease;
                &:nth-of-type(1n) {
                    &:hover {
                        cursor: pointer;
                        color: green;
                    }
                }
                &:nth-of-type(2n) {
                    &:hover {
                        cursor: pointer;
                        color: red;
                    }
                }
            }
        }
    }
}

#deleting {
    cursor: initial;
    &:hover {
        background-color: #8d71ff;
    }
}

#active {
    background-color: #cabed0;
    color: black;
    .buttons {
        z-index: 1;
    }
}
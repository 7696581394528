@use './variables' as *;

@mixin unstyle {
    border: 0;
    outline: none;
}

@mixin content-width {
    overflow: auto;
    padding: 32px 128px;
    width: 96%;
}

@mixin button {
    background-color: $purple;
    border: 0;
    border-radius: 12px;
    color: white;
    font-family: 'Poppins';
    font-size: $desktopSubheaderSize;
    font-weight: 900;
    height: 60px;
    margin-top: 15%;
    width: 100%;
}

@mixin content-left {
    height: calc(100% - 2.875em);
    min-width: 345px;
    padding: 1em;
    padding-top: 1.875em;
    width: calc(25% - 2em);
}
@use '../abstracts' as *;

.checklistContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.875em;
    width: calc(35% - 3.75em);
    .checklist {
        height: 45%;
        display: flex;
        flex-direction: column;
        &:nth-of-type(2n) {
            height: 55%;
            .checklistHeader {
                padding-top: 1em
            }
        }
        .checklistInner {
            height: 100%;
            padding-right: 0.5em;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .checklistHeader {
        align-items: center;
        display: flex;
        font-size: $desktopHeaderSize;
        font-weight: 900;
        justify-content: space-between;
        margin: auto;
        padding: 1em 0;
        padding-top: 0;
        width: 100%;
    }
}
@use '../abstracts' as *;

.featureGallery {
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
    height: 100%;
    justify-content: center;
    text-align: center;
    span {
        &:nth-of-type(1n) {
            font-size: $desktopHeaderSize;
            font-weight: 900;
        }
        &:nth-of-type(2n) {
            font-size: $desktopSubheaderSize;
            font-weight: 100;
            padding: 0 32px;
            margin-top: 10%;
        }
    }
}
@use '../abstracts' as *;

.landing {
    align-items: center;
    display: flex;
    height: calc($desktopContentHeight - 128px);
    padding: 64px;
    justify-content: center;
    div {
        width: calc(50% - 64px);
        &:nth-child(1n) {
            margin-right: 64px;
        }
        &:nth-child(2n) {
            margin-left: 64px;
        }
    }
}
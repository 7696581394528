@use '../abstracts/' as *;
// split a lot of these into smaller files as you gradually break things up
.home {
    background-color: #F6F6F6;
    display: flex;
    height: $desktopContentHeight;
    .dataEntry {
        width: 37%;
        height: 100%;
        border-right: solid #d8d8d8 1px;
    }
    .hidden {
        display: none;
    }
    .loading {
        width: 35%;
    }
}
@use '../abstracts/' as *;

.inputContainer {
    align-items: center;
    display: flex;
    font-size: $desktopSubheaderSize;
    height: 2.5em;
    justify-content: space-between;
    width: 100%;
    + .inputContainer {
        margin-top: 1em;
    }
    input {
        font-family: 'Poppins';
        font-size: $desktopSubheaderSize;
        padding: 6px;
        width: 50%;
    }
    textarea {
        font-family: 'Poppins';
        font-size: $desktopSubheaderSize;
        height: 10em;
        padding: 6px;
        width: 50%;
    }
}

.inputContainer#textarea {
    height: fit-content;
}
@use '../abstracts' as *;

.dateNotes {
    background-color: #FFFFFF;
    border-right: solid #D8D8D8 1px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.875em);
    min-width: 345px;
    padding: 1em;
    padding-top: 1.875em;
    width: calc(25% - 2em);
    .header {
        font-size: $desktopHeaderSize;
        margin: auto;
        margin-bottom: 1em;
        min-width: calc(342px * .9);
        width: calc(20.625vw * .9);
    }
    .notes {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: $desktopHeaderSize;
        width: 90%;
        margin: 0 auto;
        margin-top: 1em;
        min-width: calc(342px * .9);
        width: calc(20.625vw * .9);
        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
        }
        textarea {
            font-family: 'Poppins';
            flex-grow: 1;
            border: 0;
            border-top: solid #f2f2f2 1px;
            &:focus-visible {
                border: 0;
                outline: 0;
            }
        }
    }
}
.test {
    height: 20.625vw;
    aspect-ratio: 1;
    margin: auto;
    min-height: 342px;
}
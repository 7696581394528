@use './abstracts' as *;

.sidebar {
    align-items: center;
    background-color: #FFFFFF;
    display: flex;
    border-right: solid #e2e2e2 2px;
    flex-direction: column;
    min-width: 95px;
    padding: 24px 12px;
    width: 5%;
    height: calc(100% - 48px);
    img {
        // background-color: #cecece;
        border-radius: 7px;
        margin-top: 24px;
        width: 75%;
    }
}